import axios from 'axios';
import router from '../../router';
import { errorToMessage } from '../../functions/errorHandler';
import { ENDPOINTS_ROOT } from '../../PS-Instellingen/Server.config';

// import { axiosErrorHandler } from '../../functions/errorHandler';

// Timeout variables to clear timeouts if necessary
var refreshTimeout;
var logoutTimeout;

let API_KEY, loginURL, refreshURL, changePasswordURL, passwordResetEmailURL, verifyOobCodeURL, resetPasswordURL;

if (process.env.NODE_ENV == 'development') {
    // API_KEY = 'AIzaSyAMqWkZKvGS3pmoktVBdMi4mt9PXBhPjLM';
    API_KEY = 'AIzaSyBFysQmQkCoCaRjR0g3zM0pRx8S3KesIkc';
    loginURL = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${API_KEY}`;
    refreshURL = `https://securetoken.googleapis.com/v1/token?key=${API_KEY}`;
    changePasswordURL = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${API_KEY}`;
    passwordResetEmailURL = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`;
    verifyOobCodeURL = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${API_KEY}`;
    resetPasswordURL = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${API_KEY}`;
} else {
    loginURL = `${ENDPOINTS_ROOT}/aanmelden.php`;
    refreshURL = `${ENDPOINTS_ROOT}/refresh_token.php`;
    changePasswordURL = `${ENDPOINTS_ROOT}/wachtwoord_wijzigen_verwerking.php`;
    passwordResetEmailURL = `${ENDPOINTS_ROOT}/wachtwoord_vergeten.php`;
    resetPasswordURL = `${ENDPOINTS_ROOT}/nieuw_wachtwoord_verwerking.php`;
}

const state = {
    idToken: null,
    userId: null,
    refreshToken: null,
    expirationDate: null,
};
const mutations = {
    LOGIN(state, auth) {
        state.idToken = auth.idToken;
        state.userId = auth.userId;
        state.refreshToken = auth.refreshToken;
        state.expirationDate = auth.expirationDate;

        if (auth.refreshToken) {
            localStorage.setItem('mijnps-refreshToken', auth.refreshToken);
        }
    },
    LOGOUT(state) {
        state.idToken = null;
        state.userId = null;
        state.refreshToken = null;
        state.expirationDate = null;
        state.user = null;

        localStorage.removeItem('mijnps-refreshToken');

        router.replace('/login');
    },
};
const actions = {
    login({ commit, dispatch }, auth) {
        commit('CLEAR_ERROR');
        axios
            .post(loginURL, {
                email: auth.email,
                password: auth.password,
                returnSecureToken: true,
            })
            .then((response) => {
                const { idToken, refreshToken, expiresIn, localId } = response.data;

                // Maak auth object aan om op te slaan
                const mijnpsAuth = {
                    userId: localId,
                    idToken: idToken,
                    refreshToken: auth.onthoudMe ? refreshToken : null,
                    expirationDate: new Date(Date.now() + expiresIn * 1000),
                };

                // Als onthouden van login, timeout instellen om sessie te verversen
                // Anders uitloggen na verlopen sessie
                if (auth.onthoudMe) {
                    refreshTimeout = setTimeout(() => {
                        dispatch('refreshLogin');
                    }, expiresIn * 1000);
                } else {
                    logoutTimeout = setTimeout(() => {
                        commit('LOGOUT');
                        commit('CLEAR_USER_DATA');
                    }, expiresIn * 1000);
                }

                // Schrijf naar state
                commit('LOGIN', mijnpsAuth);

                // Gegevens ophalen
                dispatch('getUserData')
                    .then(() => {
                        router.replace('/home');
                    })
                    .catch(() => {});

                // Redirect naar Startpagina
            })
            .catch((err) => {
                const msg = errorToMessage(err);
                console.error(msg);
                commit('SET_ERROR', msg);
            });
    },
    async refreshLogin({ commit, dispatch }) {
        const token = localStorage.getItem('mijnps-refreshToken');
        try {
            if (token) {
                const res = await axios.post(refreshURL, {
                    grant_type: 'refresh_token',
                    refresh_token: token, // Voor gebruik bij Firebase testomgeving
                    Refresh_token: token,
                });

                if (!res.data) {
                    console.error('Error refreshing session');
                    dispatch('logout');
                    return false;
                }

                const { expires_in, refresh_token, id_token, user_id } = res.data;

                const mijnpsAuth = {
                    userId: user_id,
                    idToken: id_token,
                    refreshToken: refresh_token,
                    expirationDate: new Date(Date.now() + expires_in * 1000),
                };

                // Schrijf naar state
                commit('LOGIN', mijnpsAuth);

                await dispatch('getUserData');

                // Refresh herhalen
                refreshTimeout = setTimeout(() => {
                    dispatch('refreshLogin');
                }, expires_in * 1000);

                const redirectPaths = ['/login', '/reset-wachtwoord'];
                if (redirectPaths.includes(router.currentRoute.fullPath)) {
                    router.replace('/home');
                }
                return true;
            }
        } catch (err) {
            localStorage.removeItem('mijnps-refreshToken');
        }
        return false;
    },
    changePassword({ state, dispatch, commit }, password) {
        commit('CLEAR_ERROR');
        clearTimeout(refreshTimeout);
        clearTimeout(logoutTimeout);
        axios
            .post(changePasswordURL, {
                Id_token: state.idToken,
                idToken: state.idToken, // Voor testomgeving via FireBase
                Password: password,
                password: password, // Voor testomgeving via FireBase
                returnSecureToken: true,
            })
            .then((response) => {
                const { idToken, localId, expiresIn, refreshToken } = response.data;
                const onthoudMe = localStorage.getItem('mijnps-refreshToken');
                const authObj = {
                    userId: localId,
                    idToken: idToken,
                    refreshToken: onthoudMe ? refreshToken : null,
                    expirationDate: new Date(Date.now() + expiresIn * 1000),
                };

                // Als onthouden van login, timeout instellen om sessie te verversen
                if (onthoudMe) {
                    localStorage.setItem('mijnps-refreshToken', refreshToken);
                    refreshTimeout = setTimeout(() => {
                        dispatch('refreshLogin');
                    }, expiresIn * 1000);
                } else {
                    logoutTimeout = setTimeout(() => {
                        commit('LOGOUT');
                        commit('CLEAR_USER_DATA');
                    }, expiresIn * 1000);
                }

                commit('LOGIN', authObj);

                dispatch('addNotification', 'Wachtwoord gewijzigd');
            })
            .catch((err) => {
                const msg = errorToMessage(err);
                console.error(msg);
                commit('SET_ERROR', msg);
            });
    },
    requestPasswordReset(state, email) {
        axios
            .post(passwordResetEmailURL, {
                requestType: 'PASSWORD_RESET',
                Email: email,
                email: email, // TESTOMGEVING
            })
            .then(() => {
                // dispatch(
                //     'addNotification',
                //     'Aanvraag verzonden.\nAls dit account bestaat ontvang je een email om het wachtwoord in te stellen.'
                // );
                return true;
            })
            .catch(() => {
                // dispatch(
                //     'addNotification',
                //     'Aanvraag verzonden.\nAls dit account bestaat ontvang je een email om het wachtwoord in te stellen.'
                // );
                return true;
            });
    },
    resetPassword({ dispatch, commit }, obj) {
        commit('CLEAR_ERROR');
        const { oobCode, password } = obj;

        if (process.env.NODE_ENV != 'development') {
            // In productie
            axios
                .post(resetPasswordURL, {
                    oobCode: oobCode,
                    newPassword: password,
                })
                .then(() => {
                    dispatch(
                        'addNotification',
                        'Wachtwoord opnieuw ingesteld. Je kunt nu met het nieuwe wachtwoord inloggen'
                    );
                    router.replace('/login');
                })
                .catch((err) => {
                    const msg = errorToMessage(err);
                    console.error(msg);
                    commit('SET_ERROR', msg);
                });
        }

        if (process.env.NODE_ENV === 'development') {
            // Verify oobCode -- In TEST OMGEVING
            axios
                .post(verifyOobCodeURL, {
                    oobCode: oobCode,
                })
                .then((response) => {
                    if (response.status === 200) {
                        // If valid, set password
                        axios
                            .post(resetPasswordURL, {
                                oobCode: oobCode,
                                newPassword: password,
                            })
                            .then(() => {
                                dispatch(
                                    'addNotification',
                                    'Wachtwoord opnieuw ingesteld. Je kunt nu met het nieuwe wachtwoord inloggen'
                                );
                                router.replace('/login');
                            })
                            .catch((err) => {
                                console.warn({ err });
                            });
                    } else {
                        commit('SET_ERROR', 'Er ging iets mis met het aanpassen van het wachtwoord');
                    }
                })
                .catch((err) => {
                    console.warn({ err });
                });
        }
    },
    logout({ commit }) {
        clearTimeout(refreshTimeout);
        clearTimeout(logoutTimeout);

        commit('LOGOUT');
        commit('CLEAR_USER_DATA');
    },
};
const getters = {
    isLoggedIn(state) {
        return state.userId != null && state.idToken != null;
    },
    getIdToken(state) {
        return state.idToken;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
