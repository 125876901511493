const state = {
    fotos: null,
};

const mutations = {
    SET_FOTOLIJST(state, lijst) {
        state.fotos = lijst;
    },
    CLEAR_FOTOLIJST(state) {
        state.fotos = null;
    }
};

const actions = {
    setFotos({ commit }, fotolijst) {
        const arr = []
        if (fotolijst.foto) { arr.push({ Bestandsnaam: fotolijst.foto }) }
        if (fotolijst.extra_fotos) { arr.push(...fotolijst.extra_fotos) }

        commit('SET_FOTOLIJST', arr);
    },
    clearFotos({ commit }) {
        commit('CLEAR_FOTOLIJST')
    }
};
const getters = {
    fotolijst(state) {
        return state.fotos;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
