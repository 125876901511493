/*
Hier vind je de informatie die ingeladen kan worden voor de contactpersoon op de homepage. 
Let bij het toevoegen van nieuwe medewerkers goed op de komma die in de array nodig is. 
De naam dient exact hetzelfde te zijn als de naam die de server verstuurt.
Plaatje wordt nooit groter dan 150x150 pixels. Laad dus geen grotere bestanden in dan nodig.

*/

export default [
    {
        naam: 'ARJAN',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2020/09/arjan-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'AMBER',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2021/11/optie-2-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'BAHAR',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2020/09/bahar-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'BAS',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2020/09/bas-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'MATCHMAKER',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2018/07/test11_split_b.png',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'MICHELLE',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2023/04/michelle-150x150.jpeg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'ROBIN',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2022/12/robin.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'ROGIER',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2020/09/rogier-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
    {
        naam: 'TANJA',
        fotoUrl: 'https://www.partnerselect.net/wp-content/uploads/2021/03/Profielfoto-Tanja-1-150x150.jpg',
        doorkiesNummer: '',
        emailadres: '',
    },
];
