import axios from 'axios';
import AuthModule from './Auth';
import { parsedJson } from '../../../TESTJSON';
import { initBericht } from '../../functions/berichtTemplate';
import Contactpersonen from '../../PS-Instellingen/Contactpersonen.config';
import { ENDPOINTS_ROOT } from '../../PS-Instellingen/Server.config.js';

const GetUserDataURL = `${ENDPOINTS_ROOT}/ophalen_gegevens.php`;
const sendMessageURL = `${ENDPOINTS_ROOT}/bericht_versturen.php`;
const markAsReadURL = `${ENDPOINTS_ROOT}/bericht_gelezen.php`;
const belafspraakURL = `${ENDPOINTS_ROOT}/belafspraak_maken.php`;
const wijzigFotoStatusURL = `${ENDPOINTS_ROOT}/verander_foto_status_voorstel.php`;

const state = {
    user: null,
    berichten: null,
    voorstellen: null,
    algemeneBerichten: null,
    belafspraakLink: null,
};

const mutations = {
    SET_USER_DATA(state, user) {
        const { klantgegevens, berichten, voorstellen, algemene_berichten } = user;
        state.user = klantgegevens;
        state.berichten = berichten;
        state.voorstellen = voorstellen;
        state.algemeneBerichten = algemene_berichten.map((e) => {
            e.isGelezen = false;
            return e;
        });
    },
    TEST_DATA(state, user) {
        const { klantgegevens, berichten, voorstellen, algemene_berichten } = user;
        state.user = klantgegevens;
        state.berichten = berichten;
        state.voorstellen = voorstellen;
        if (state.algemeneBerichten === null) {
            state.algemeneBerichten = algemene_berichten.map((e) => {
                e.isGelezen = false;
                return e;
            });
        }
    },
    CLEAR_USER_DATA(state) {
        state.user = null;
        state.berichten = null;
        state.voorstellen = null;
        state.algemeneBerichten = null;
    },
    SET_BELAFSPRAAK_LINK(state, url) {
        state.belafspraakLink = url;
    },
};
const actions = {
    getUserData({ commit, dispatch }) {
        // IN testomgeving niet naar server vragen.
        if (process.env.NODE_ENV === 'development') {
            dispatch('testGetJSON');
            return;
        }

        axios
            .post(GetUserDataURL, {
                Id_token: AuthModule.state.idToken,
            })
            .then((response) => {
                commit('SET_USER_DATA', response.data);
                dispatch('haalBelafspraakLink');
            })
            .catch(() => console.error('Fout bij ophalen gegevens'));
    },
    testGetJSON({ commit }) {
        commit('TEST_DATA', parsedJson);
    },
    async stuurBericht({ dispatch }, message) {
        const { typeBericht, inhoudBericht } = message;
        const bericht = initBericht();

        bericht.Soort_bericht_code = typeBericht;
        bericht.Bericht = inhoudBericht;

        const bevestiging = await dispatch('verzendBericht', bericht);
        return bevestiging;
    },
    async stuurReactieOpVoorstel({ dispatch }, reactieOpVoorstel) {
        const { reactie, motivatie, voorstelNummer } = reactieOpVoorstel;
        const bericht = initBericht();

        bericht.Soort_bericht_code = reactie;
        bericht.Voorstelnummer = voorstelNummer;
        bericht.Bericht = motivatie;

        const bevestiging = await dispatch('verzendBericht', bericht);
        return bevestiging;
    },
    async stuurWijzigingPB({ dispatch }, tekst) {
        const bericht = initBericht();
        bericht.Soort_bericht_code = 1;
        bericht.Bericht = tekst;

        const bevestiging = await dispatch('verzendBericht', bericht);
        return bevestiging;
    },
    async verzendBericht({ state }, bericht) {
        bericht.idToken = AuthModule.state.idToken;
        bericht.Relator_nummer = state.user.DbNrPS;

        const bevestiging = await axios.post(sendMessageURL, bericht);
        return bevestiging;
    },
    async markeerAlsGelezen({ state }, berichtNummer) {
        const idToken = AuthModule.state.idToken;
        const relatorNummer = state.user.DbNrPS;

        const bevestiging = await axios.post(markAsReadURL, {
            Id_token: idToken,
            Relatornummer: relatorNummer,
            Berichtnummer: berichtNummer,
        });
        return bevestiging;
    },
    markeerAlgemeenBerichtAlsGelezen({ state }, index) {
        state.algemeneBerichten[index].isGelezen = true;
    },
    haalBelafspraakLink({ state, commit }) {
        if (state.user) {
            axios
                .post(belafspraakURL, {
                    idToken: AuthModule.state.idToken,
                    Relator_nummer: state.user.DbNrPS,
                    Voornaam: state.user.Voornaam,
                    Email: state.user.Email,
                })
                .then((response) => {
                    let url = response.data.url_agenda;
                    commit('SET_BELAFSPRAAK_LINK', url);
                })
                .catch(() => {
                    commit('SET_BELAFSPRAAK_LINK', null);
                });
        }
    },
    veranderFotoStatus({ state, dispatch }, payload) {
        axios.post(wijzigFotoStatusURL, {
            Id_token: AuthModule.state.idToken,
            Relatornummer: state.user.DbNrPS,
            Voorstelnummer: payload.voorstelnummer,
            Te_kiezen_foto_code: payload.gekozenCode,
        }).then(() => {
            dispatch('getUserData');
        }).catch(() => {
            console.error('Kon fotovertoningsstatus niet wijzigen')
        })
    }
};
const getters = {
    user(state) {
        return state.user;
    },
    naamBemiddelaar(state) {
        let naam = state.user.Bemiddelaar.split('').map((e, i) => (i === 0 ? e.toUpperCase() : e.toLowerCase()));
        return naam.join('');
    },
    relatorNummer(state) {
        let nummer = parseInt(state.user.DbNrPS);
        return nummer;
    },
    ontvangenBerichten(state) {
        if (!state.berichten) {
            return null;
        }
        return state.berichten.filter((e) => e.Ontv_Verz === '0');
    },
    verzondenBerichten(state) {
        if (!state.berichten) {
            return null;
        }
        return state.berichten.filter((e) => e.Ontv_Verz === '1');
    },
    haalBerichtNummer: (state) => (nummer) => {
        const bericht = state.berichten.filter((e) => e.Nummer === nummer);
        return bericht[0];
    },
    voorstellen(state) {
        return state.voorstellen;
    },
    haalVoorstelNummer: (state) => (nummer) => {
        const voorstel = state.voorstellen.filter((e) => e.Voorstelnummer === nummer);
        return voorstel[0];
    },
    algemeneBerichten(state) {
        if (state.algemeneBerichten) {
            let arr = state.algemeneBerichten.map((e) => {
                e.isGelezen = false;
                return e;
            });
            return arr;
        } else {
            return null;
        }
    },
    belafspraakLink(state) {
        if (state.voorstellen && state.voorstellen.length < 1 && state.belafspraakLink) {
            return `${state.belafspraakLink}&kg=1`;
        }
        return state.belafspraakLink;
    },
    contactpersoon(state) {
        const contact = Contactpersonen.reduce((a, c) => {
            return c.naam.toLowerCase() === state.user.Bemiddelaar.toLowerCase() ? c : a;
        }, null);
        return contact;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
